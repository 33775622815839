import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import callAPI from '../../../helpers/callAPI'
import Loading from '../../../templates/UI/Loading/Loading'
import Pill from '../../../templates/UI/Pill/Pill'
import CampaignTrafficType from '../../CampaignTrafficType/CampaignTrafficType'

const CampaignInfo = ({ offerId, showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [campaign, setCampaign] = useState({})
    const [approvalInfo, setApprovalInfo] = useState({})

    useEffect(() => {
        getOfferInfo()
    }, [offerId])

    const getOfferInfo = () => {
        let url = `campaign/${offerId}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success']) {
                    setCampaign(result.campaign)
                    getAdvertiserInfo(result.campaign.advertiser.id)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'An error occurred while fetching campaign info')
                setDataLoaded(true)
            })
    }

    const getAdvertiserInfo = (advertiserId) => {
        let url = `advertiser/${advertiserId}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success']) {
                    setApprovalInfo(result.approval_info)
                } else {
                    showSystemMessage('error', result.errors.message)                   
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'An error occurred while fetching advertiser approval info')
            })
    }

    const replace_approval_text = (approval_text) => {
        let text = ''
        switch (approval_text){
            case 'NOT_REQUIRED':
                text = 'Placement not needed'
            break;
            case 'ADVERTISER_REQUIRED':
                text = 'Placement needed with advertiser approval'
            break;
            case 'MB_REQUIRED':
                text = 'Placement needed with approval at our discretion'
            break;
            default:
                text = 'Unknown'
            break;
        }

        return text
    }

    return (
        <div className="card mb-3">
            <div className="card-header">Campaign Info</div>
            <div className="card-body p-0">
                {dataLoaded ? (
                    <div>
                        <div className="border-bottom align-items-center p-2">
                            <div className='d-flex'>
                                <Link to={`/campaign/${offerId}`}>
                                    <h5 className="font-weight-bold">{campaign.name}</h5>
                                </Link>
                                <div>({offerId})</div>
                            </div>
                            <Link to={`/advertiser/${campaign.advertiser.id}`}>{campaign.advertiser.name}</Link> ({campaign.advertiser.id})<br></br>
                            {campaign.advertiser.contact}: <a href={`mailto:${campaign.advertiser.email}`}>{campaign.advertiser.email}</a>
                        </div>

                        <div className="d-flex align-items-center justify-content-between p-2 border-bottom">
                            <div>
                                <CampaignTrafficType allowed={campaign.tracking.traffic.traffic_types.site_traffic_flag} name="Display" />
                                <CampaignTrafficType allowed={campaign.tracking.traffic.traffic_types.social_media_flag} name="Social" />
                                <CampaignTrafficType allowed={campaign.tracking.traffic.traffic_types.contextual_flag} name="Contextual" />
                                <CampaignTrafficType allowed={campaign.tracking.traffic.traffic_types.search_flag} name="Search" />
                                <CampaignTrafficType allowed={campaign.tracking.traffic.traffic_types.mobile_flag} name="Mobile Ads" />
                                <CampaignTrafficType allowed={campaign.tracking.traffic.traffic_types.incentive_flag} name="Incentive" />
                                <CampaignTrafficType allowed={campaign.tracking.traffic.traffic_types.solo_emails_flag} name="Email" />
                                <CampaignTrafficType allowed={campaign.tracking.traffic.traffic_types.native_flag} name="Native Ads" />
                                <CampaignTrafficType allowed={campaign.tracking.traffic.traffic_types.brand_bid_flag} name="Brand Bidding" />
                            </div>
                        </div>


                        <div>
                            <div className="border-bottom">
                                <div className='p-2'>
                                    <span className="d-block text-secondary">Advertiser Approval Requirements</span>
                                    <div>{approvalInfo.placement_approval_requirement ? replace_approval_text(approvalInfo.placement_approval_requirement) : ''}</div>
                                </div>
                            </div>
                            <div>
                                <div className='p-2'>
                                    <span className="d-block text-secondary">Advertiser Approval Notes</span>
                                    <div dangerouslySetInnerHTML={{__html: approvalInfo.custom_approval_notes ? approvalInfo.custom_approval_notes : ''}}></div>
                                </div>
                            </div>                            
                        </div>

                        {(campaign.campaign_approval.approval_preface ||
                            campaign.campaign_approval.approval_criteria) && (
                                <div>
                                    <table className='table table-striped m-0'>
                                        <tbody>
                                            {campaign.campaign_approval.approval_preface && (
                                                <tr>
                                                    <td>
                                                        <span className="d-block text-secondary">Approval Preface</span>
                                                        <span dangerouslySetInnerHTML={{__html: campaign.campaign_approval.approval_preface}}></span>
                                                    </td>
                                                </tr>
                                            )}
                                            {campaign.campaign_approval.approval_criteria && (
                                                <tr>
                                                    <td>
                                                        <span className="d-block text-secondary">Approval Criteria</span>
                                                        <span dangerouslySetInnerHTML={{__html: campaign.campaign_approval.approval_criteria}}></span>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}                        
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default CampaignInfo
